<template>
  <div>
    <div class="layout-banner">
      <div class="owl-carousel owl-theme banner-carousel">
        <carousel
          :autoplay="false"
          :dots="false"
          :responsive="{
            0: { items: 1, nav: false },
            600: { items: 1, nav: true },
            1100: { items: 1, nav: false },
          }"
        >
          <div class="item">
            <div>
              <img src="@/assets/images/banner-paryushan-2021.jpg" class="img-fluid">
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <live :lang="lang" />
    <videos/>
  <!--
   <section id="aalochana" class="mt-5">
      <h2 class="border-title text-center my-2">Aalochana Book</h2>
      <div class="container my-5">
        <h4 class="text-center my-2">Formatted for Printout</h4>
        <div class="row max-con">
          <div class="offset-lg-3 col-lg-3 col-10 offset-1">
            <a
              href="https://prsdm.in/alochana-print"
              target="_blank"
              type="button"
              class="btn btn-danger btn-lg btn-block"
            >Gujarati</a>
          </div>
          <div class="offset-lg-0 col-lg-3 col-10 offset-1 mt-2 mt-lg-0">
            <a
              href="https://prsdm.in/alochana-print-en"
              target="_blank"
              type="button"
              class="btn btn-danger btn-lg btn-block"
            >English</a>
          </div>
        </div>
        <h4 class="text-center my-2 mt-5">Formatted for online Viewing</h4>
        <div class="row max-con">
          <div class="offset-lg-3 col-lg-3 col-10 offset-1">
            <a
              href="https://prsdm.in/alochana-view"
              target="_blank"
              type="button"
              class="btn btn-primary btn-lg btn-block"
            >Gujarati</a>
          </div>
          <div class="offset-lg-0 col-lg-3 col-10 offset-1 mt-2 mt-lg-0">
            <a
              href="https://prsdm.in/alochana-view-en"
              target="_blank"
              type="button"
              class="btn btn-primary btn-lg btn-block"
            >English</a>
          </div>
        </div>
      </div>
      <hr class="w-75">
      <h2 class="border-title text-center my-2">Samvatsari Aalochana & Pratikraman Aaradhana</h2>
      <div class="row justify-content-center">
        <div class="col-12 col-md-5">
          <p class="text-muted text-center font-weight-bold mt-3">Paryushan Aalochana</p>

          <div class="mt-5">
            <iframe
              src="https://www.youtube.com/embed/7JDkRChPx0A"
              style="display:block; border: none"
              allowfullscreen
              title="YouTube Video"
              height="450"
              width="100%"
            ></iframe>
            <br>
            <br>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <p class="text-muted text-center font-weight-bold mt-3">Samvatsari Pratikraman Aaradhana</p>

          <div class="mt-5">
            <iframe
              src="https://www.youtube.com/embed/d-T55-cHQXk"
              style="display:block; border: none"
              allowfullscreen
              title="YouTube Video"
              height="450"
              width="100%"
            ></iframe>
            <br>
            <br>
          </div>
        </div>
      </div>
    </section>
    -->

    <!-- TELECAST & WEBCAST -->
    <!--<section>
      <div class="row pt-5">
        <div class="col-12 col-lg-6 text-center">
          <h4 class="card-title border-title">LIVE TELECAST</h4>
          <div class="row">
            <div class="col-12 col-lg-6">
              <span class="img-span" @click="modals.modal0 = true">
                <img
                  class="telecast-img"
                  src="@/assets/images/astha-bhajan.jpeg"
                  alt="astha bhajan"
                >
              </span>
            </div>
            <div class="col-12 col-lg-6">
              <span class="img-span" @click="modals.modal1 = true">
                <img class="telecast-img" src="@/assets/images/soham.jpeg" alt="soham">
              </span>
            </div>
            <div class="col-12 col-lg-6 mt-1">
              <span class="img-span" @click="modals.modal2 = true">
                <img class="telecast-img" src="@/assets/images/paras-tv.png" alt="paras tv">
              </span>
            </div>
            <div class="col-12 col-lg-6 mt-1">
              <span class="img-span">
                <img class="telecast-img" src="@/assets/images/gtpl-bhakti.png" alt="gtpl-bhakti">
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 text-center">
          <h4 class="card-title mt-5 mt-lg-0 border-title">LIVE WEBCAST</h4>
          <div class="row pt-5">
            <div class="col-12 col-lg-4 p-0">
              <a href="https://prsdm.in/zoom" target="_new">
                <img src="@/assets/images/zoom.png" alt="zoom" class="webcast-img">
              </a>
              <p class="font-weight-bold mt-3 mb-0">
                Meeting ID:
                <span class="zoom-text">208 108 1008</span>
              </p>
              <p class="font-weight-bold">
                Password:
                <span class="zoom-text">108</span>
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <a href="https://www.youtube.com/user/parasdhamtv" target="_new">
                <img src="@/assets/images/youtube.png" alt="youtube" class="webcast-img">
              </a>
              <p class="font-weight-bold youtube-text">@ParasdhamTV</p>
            </div>
            <div class="col-12 col-lg-4">
              <a href="https://www.facebook.com/ParasdhamIndia/" target="_new">
                <img src="@/assets/images/facebook.jpeg" alt="facebook" class="webcast-img">
              </a>
              <p class="font-weight-bold fb-text">@ParasdhamIndia</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <modal :show.sync="modals.modal0" type="notice">
      &lt;!&ndash; <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
      </template>&ndash;&gt;
      <div>
        <div class="row program p-3">
          <div class="col-6 col-lg-4">
            <h6 class="program-title">INNER CLEANING MEDITATION COURSE</h6>
            <h6 class="text-white">7:00 - 8:00 AM</h6>
          </div>
          <div class="col-6 col-lg-4">
            <h6 class="program-title">PRAVADHIRAJ PRAVACHAN MALA</h6>
            <h6 class="text-white">9:00 - 11:00 AM</h6>
          </div>
          <div class="col-6 col-lg-4">
            <h6 class="program-title">NIGHT PRAVACHAN</h6>
            <h6 class="text-white">8:15 - 10:15 PM</h6>
          </div>
        </div>

        <div class="row p-3 py-5">
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/tata-sky.png" alt="Tata sky" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 1077</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/dishtv.png" alt="dishtv" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 1073</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/d2h.png" alt="d2h" class="pop-img" height="70px">
            </div>
            <h6 class="text-dark">Channel No. 487</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/dd-free-dish.png" alt="dd-free-dish" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 891</h6>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.modal0 = false">Close</base-button>
      </template>
    </modal>

    <modal :show.sync="modals.modal1" type="notice">
      &lt;!&ndash; <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
      </template>&ndash;&gt;
      <div>
        <div class="row program p-3">
          <div class="col-6 col-lg-3 p-0">
            <h6 class="program-title">INNER CLEANING MEDITATION COURSE</h6>
            <h6 class="text-white">7:00 - 8:00 AM</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <h6 class="program-title">PRAVADHIRAJ PRAVACHAN MALA</h6>
            <h6 class="text-white">9:00 - 11:00 AM</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <h6 class="program-title">AAVASHYAK AARADHANA</h6>
            <h6 class="text-white">7:00 - 8:15 PM</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <h6 class="program-title">NIGHT PRAVACHAN</h6>
            <h6 class="text-white">8:15 - 10:15 PM</h6>
          </div>
        </div>

        <div class="row p-3 py-5">
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/hathway.png" alt="hathway" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 841</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/gtpl.png" alt="gtpl" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 561</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/digi-cable.png" alt="digi" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 481</h6>
          </div>
          <div class="col-6 col-lg-3 p-0">
            <div class="img-logo">
              <img src="@/assets/images/rajesh.png" alt="rajesh" class="pop-img">
            </div>
            <h6 class="text-dark">Channel No. 466</h6>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.modal1 = false">Close</base-button>
      </template>
    </modal>
    <modal :show.sync="modals.availableSoonModal" type="notice">
      <div>
        <div class="row p-3">
          <div class="col-12 p-0">
            <h6>PDF will be available soon for download.</h6>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.availableSoonModal = false">Close</base-button>
      </template>
    </modal>

    <section class="schedule&#45;&#45;table" id="paryushan-schedule">
      <h2 class="border-title text-center">Parvadhiraj Paryushan Schedule</h2>
      <br>
      <p
        class="text-center font-italic mb-1"
      >Paryushan Schedule of Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb</p>
      <p class="text-center font-italic"></p>
      <br>
      <div class="container">
        <div class="row max-con mb-5">
          <div class="offset-lg-3 col-lg-3 col-10 offset-1">
            <a
              href="https://drive.google.com/uc?id=1oom9OrnHya36PkatfxaqG7vMjLhTbyhU"
              target="_blank"
              type="button"
              class="btn btn-danger btn-lg btn-block"
            >Parvadhiraj Paryushan Mahaparva Schedule(Hindi)</a>
          </div>
          <div class="offset-lg-0 col-lg-3 col-10 offset-1 mt-2 mt-lg-0">
            <a
              href="https://drive.google.com/uc?id=1BbvhLBkG8kGAkcz4Cf5wCzfymWL7_MW5"
              target="_blank"
              type="button"
              class="btn btn-danger btn-lg btn-block"
            >Parvadhiraj Paryushan Mahaparva Schedule(English)</a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="bg-light rounded-pill p-3">
              <h5 class="mb-0 text-center">Morning</h5>
            </div>

            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">7.00 – 8.00 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Inner Cleaning Meditation Course</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Rashtrasant Param Gurudev’s uncommon practical
                      experiments to
                      overcome common problems
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">8.15 – 8.30 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Shubh Bhavna Mantra Sadhana</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Channelling peace, positivity and harmony through Mantra
                      sadhana
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">8.30 – 9.00 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Paryushan Session (English)</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Life enriching sessions by highly educated Param
                      Mahasatijis
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">9.00 – 11.00 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Parvadhiraj Pravachan Mala</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Life transforming and soul enhancing Pravachans by
                      Rashtra Sant Param Gurudev followed by Pacchkhan Vidhi
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">11.00 – 11:15 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Live Girnar Bhakti</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Drench in Bhakti for Parmatma Neminaath
                      Singer: Shri Hardikbhai Shah
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">11:15 – 11:30 AM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Bhaav Yatra</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">A journey to think, contemplate and reflect</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-light rounded-pill p-3 mt-5">
              <h5 class="mb-0 text-center">Evening</h5>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">4:00 – 5:00 PM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Baal Paryushan Aaradhana Mahotsav</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="text-muted mb-0">
                      Paryushan Parva Aradhana updesh for young minds and youth
                      souls by Param Mahasatiji
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">7:00 – 8:15 PM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Pratikraman Aradhana</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="pb-2 text-muted">
                      The process of Soul
                      Purification
                    </li>
                    <li class="text-muted">Sadhak Didi and Bhai</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-light rounded-pill p-3 mt-5">
              <h5 class="mb-0 text-center">Night</h5>
            </div>
            <div class="card schedule-card rounded-0 mt-4">
              <div class="row no-gutters">
                <div
                  class="col-md-4 border-right-red px-3 py-4 d-flex align-items-center justify-content-center"
                >
                  <h3 class="text-warning s-title mb-0">8:15 – 10:15 PM IST</h3>
                </div>
                <div class="col-md-8 px-3 py-4 bg-light">
                  <h3 class="font-23">Night Pravachan along with Prabhu Bhakti</h3>
                  <ul class="list-unstyled mb-0">
                    <li class="pb-2 text-muted">Future Designing Course</li>
                    <li class="text-muted">
                      Rashtrasant Param Gurudev and
                      Pujya Vinamramuni Maharaj Saheb
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="video&#45;&#45;content bg-light" id="samvatsari-registration">
      <h3 class="border-title text-center samvatsariHeading mb-2">Samvatsari Pratikraman Aaradhana</h3>
      <div class="row justify-content-center">
        <div class="col-md-4 col-lg-10">
          <picture>
            <source media="(min-width:992px)" srcset="@/assets/images/onejain_promo_hr.jpeg">
            <img src="@/assets/images/promo.jpeg" class="img-fluid w-100" style="min-height: 430px">
          </picture>
        </div>
        <div class="col-md-6 col-lg-10">
          <div class="mt-5">
            <div class="text-center mb-2">
              <a
                href="https://onejain.org/samvatsari-registration"
                class="btn btn-warning btn-block font-22"
              >
                <i class="fa fa-user-plus mr-1"></i>
                Register Now
              </a>
            </div>

            <p
              class="text-center"
            >More than 1,11,000 seekers from India and abroad shall come together and perform Aaradhana with one and all.</p>
            <p
              class="text-center"
            >Taking the sinful activities of the soul in the reverse gear by joining together in Samuhik Samvatsari Pratikraman Aaradhana.</p>
            <p
              class="text-center"
            >Sadhaks will conduct the auspicious Samvatsari Pratikraman with bhaav, meaning and clarity.</p>
            <p
              class="text-center"
            >Jain Jagruti Centre, Jain Social Group, Jainam Group – Rajkot, shravak-shravikas from 51+ sanghs across the world shall join us in this initiative.</p>
            <p class="text-center">We Jains United in Samvatsari Pratikraman!</p>
          </div>
        </div>
      </div>
    </section>-->
    <!--<section style="padding: 80px 0;" id="tapasvi-aaradhana">
      <div class="col-md-10 offset-md-1">
        <h2 class="border-title text-center">Tapasvi Aaradhana Form</h2>
        <p class="text-center">
          In Jainism, we not only prevent the influx of Karma but we also practice the shedding of Karmas.
          Tapasya (Fasting) is one such blissful opportunity where we invite challenges.
        </p>
        <p class="text-center">
          As we walk in the path of Self-abstinence, Tapasya happens naturally and as a result it decreases our attachment towards our taste buds.
        </p>
        <p class="text-center">
          We wholeheartedly appreciate and express our heartfelt Anumodna towards the blessed souls performing Tapp Sadhana.
        </p>
        <p class="text-center">Requesting all the Tapasvis to kindly fill the form.</p>
        <iframe
                id="jotform"
                title="Tapasvi Aaradhana Form"
                onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allowfullscreen="true"
                src="https://form.jotform.com/202224832825047"
                frameborder="0"
                style="width: 1px;
                      min-width: 100%;
                      min-height:1400px;
                      border:none;"
        ></iframe>
      </div>
    </section>-->
    <section class="sanghname--content" id="sangh">
      <hr class="w-75">
      <div id="contact-us" class="text-center">
        <h4 class="text-center">
          For any queries or feedback kindly email us on
          <a
            href="mailto:connect@parasdham.org"
          >connect@parasdham.org</a>
        </h4>
        <!-- <h4 class="text-center">
          Call us at:
          +91 (0)79480 59991
        </h4> -->
      </div>
      <br>
      <br>
    </section>
  </div>
</template>

<script>
import live from "./Live.vue";
import videos from "./Videos.vue";
import carousel from "vue-owl-carousel";
import { sanghs } from "@/../dataStore.js";
import { db } from "../firestore";

export default {

  props: {
    lang: {
      type: String
    }
  },

  components: { carousel, live, videos },

  data() {
    return {
      liveSchedule: null,
      sanghs: [],
      modals: {
        modal0: false,
        modal1: false,
        modal2: false,
        availableSoonModal: false
      }
    };
  },

  firestore() {
    return {
      liveSchedule: db.collection("liveSchedule")
    };
  },

  created() {
    this.sanghs = sanghs;
  },
  methods: {
    goYtSubscribeButton() {
      // ID of element containing <div class="g-ytsubscribe"> element(s)
      // The container element already contains the button elements when this
      // function is called.
      var containerId = "sample-container";
      window.gapi.ytsubscribe.go(containerId);
    }
  },
  mounted() {
    this.goYtSubscribeButton();
  }
};
</script>
<style>
.about--content {
  background: #d78d36;
  padding: 50px 0;
}
.telecast,
.webcast {
  background-color: #f5365c;
  color: #ffffff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 20px;
  margin-right: -3px;
}
.webcast {
  border-radius: 20px;
  padding-right: 20px;
}
.webcast-img {
  height: 50px;
  width: 150px;
}
.img-span {
  padding: 20px;
  border: 4px solid #ec3948;
  height: 125px;
  width: 150px;
  display: inline-block;
  border-radius: 50%;
}
.telecast-img {
  width: 100px;
  height: 75px;
}
.youtube-text {
  color: #cf302b;
}
.fb-text {
  color: #3b5999;
}
.zoom-text {
  color: #2e8bf7;
}
.program {
  background: #2b6635;
}
.program-title {
  font-weight: bold;
  color: #f3ce47;
  height: 60px;
}
.img-logo {
  height: 80px;
}
.pop-img {
  width: 100px;
}
</style>
