<template>
  <div>
    <div v-if="liveSchedule.length >= 1">
      <section class="video--content" v-if="showVideo">
        <div class="row justify-content-center">
          <div class="col-md-7">
            <h2
              class="border-title text-center"
              v-show="liveSchedule[0].englishVideoId"
            >
              <a
                :href="link"
                class="btn btn-danger btn-lg"
                style="font-size: 1.3rem;"
              >{{message}}</a>
            </h2>
            <h2
              class="border-title text-center"
              v-show="liveSchedule[0].promoted_link != null && liveSchedule[0].promoted_link_title != null"
            >
              <a
                :href="liveSchedule[0].promoted_link"
                class="btn btn-outline-warning"
                target="_new"
              >{{ liveSchedule[0].promoted_link_title }}</a>
            </h2>
            <p class="text-muted text-center font-weight-bold mt-3">
              <span v-show="liveSchedule[0].live" class="text-danger">
                <em class="fa fa-circle mr-1"></em>
                LIVE
              </span>
              {{ liveSchedule[0].title }}
            </p>
            <div class="mt-5">
              <iframe
                :src="'https://www.youtube.com/embed/'+videoId+'?rel=0&autoplay=1'"
                style="display:block; border: none"
                allowfullscreen
                title="YouTube Video"
                height="450"
                width="100%"
                allow="fullscreen *; autoplay *"
              ></iframe>
              <br>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { db } from "../firestore";

export default {

  props: {
    lang: {
      type: String
    }
  },
  
  data() {
    return {
      liveSchedule: null,
      now: Math.floor(Date.now() / 1000)
    };
  },

  computed: {
    message() {
      if (this.lang == "en") {
        return "Switch to ગુજરાતી"
      }
      return "Switch to ENGLISH"
    },
    link() {
      if (this.lang == "en") {
        return "/"
      }
      return "/english"
    },
    videoId() {
      if (this.lang == "en" && this.liveSchedule[0].englishVideoId) {
        return this.liveSchedule[0].englishVideoId
      }
      return this.liveSchedule[0].videoid
    },
    showVideo() {
      var show = false;
      if (this.liveSchedule != null) {
        show = this.liveSchedule[0].show;
        if (this.liveSchedule[0].startTime != null) {
          show = show && this.liveSchedule[0].startTime.seconds < this.now;
        }
        if (this.liveSchedule[0].endTime != null) {
          show = show && this.liveSchedule[0].endTime.seconds > this.now;
        }
      }
      return show;
    }
  },

  firestore() {
    return {
      liveSchedule: db.collection("liveSchedule")
    };
  },

  created() {
    const self1 = this;
    setInterval(
      function() {
        self1.now = Math.floor(Date.now() / 1000);
    }, 1000);
  },

  methods: {
    goYtSubscribeButton() {
      // ID of element containing <div class="g-ytsubscribe"> element(s)
      // The container element already contains the button elements when this
      // function is called.
      var containerId = "sample-container";
      window.gapi.ytsubscribe.go(containerId);
    }
  },

  mounted() {
    this.goYtSubscribeButton();
  }

};
</script>

<style>
.about--content {
  background: #d78d36;
  padding: 50px 0;
}
.telecast,
.webcast {
  background-color: #f5365c;
  color: #ffffff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 20px;
  margin-right: -3px;
}
.webcast {
  border-radius: 20px;
  padding-right: 20px;
}
.webcast-img {
  height: 50px;
  width: 150px;
}
.img-span {
  padding: 20px;
  border: 4px solid #ec3948;
  height: 125px;
  width: 150px;
  display: inline-block;
  border-radius: 50%;
}
.telecast-img {
  width: 100px;
  height: 75px;
}
.youtube-text {
  color: #cf302b;
}
.fb-text {
  color: #3b5999;
}
.zoom-text {
  color: #2e8bf7;
}
.program {
  background: #2b6635;
}
.program-title {
  font-weight: bold;
  color: #f3ce47;
  height: 60px;
}
.img-logo {
  height: 80px;
}
.pop-img {
  width: 100px;
}
</style>
