<template>
  <section v-if="show" class="video--content">
    <h2 class="border-title text-center">Other Videos</h2>
    <template v-for="(video, index) in enabledVideos">
      <div class="row justify-content-center" :key="'video' + index">
        <div class="col-md-7">
          <p class="text-muted text-center font-weight-bold mt-3">
            {{ video.title }}
          </p>

          <div class="mt-5">
            <iframe
              :src="'https://www.youtube.com/embed/' + video.videoId + '?rel=0'"
              style="display:block; border: none"
              allowfullscreen
              title="YouTube Video"
              height="450"
              width="100%"
            ></iframe>
            <br />
            <br />
          </div>
        </div>
      </div>
      <hr class="w-75" :key="'videohr' + index" />
    </template>
  </section>
</template>

<script>
import { db } from "../firestore";

export default {
  data() {
    return {
      videos: null,
    };
  },

  computed: {
    enabledVideos() {
      return this.videos.filter((x) => x.enable);
    },
    show() {
      return this.enabledVideos.length > 0;
    },
  },

  firestore() {
    return {
      videos: db.collection("videoList"),
    };
  },
};
</script>
