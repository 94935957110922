export const liveVideo = {
    "title": "Now Playing - Limdi Gopal Sampraday’s Shashan Ratna Pujya Shri Ram Uttam Muni Maharajsaheb on One Jain",
    "videoid": "yg7EE8qh9WY",
    "live": true,
    "promoted_link": null,
    "promoted_link_title": null
}

export const sanghs = [
    {
        groupName: 'Mumbai',
        list: [
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Hingwala',
                phone: null
            },
            {
                name: 'Shri Ghatkopar Agra Road Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Garodia Nagar',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Shravak Sangh, Chembur',
                phone: null
            },
            {
                name: 'Shri Kamagali Vardhman Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shri Mahavir Sthanakwasi Jain Sangh, Dombivali (East)',
                phone: null
            },
            {
                name: 'Shri Pantnagar Vardhman Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shree Vileparle Vardhman Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Sangh, Tilak Nagar',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Lokhandwala',
                phone: null
            },
        ]
    },
    {
        groupName: 'Gujarat',
        list: [
            {
                name: 'Shri Alkapuri Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Shwetambar Sthanakwasi Jain Sangh, Karjan',
                phone: null
            },
            {
                name: 'Shree Delhi Gujarati Sthanakwasi Jain Sangh ',
                phone: null
            },
            {
                name: 'Shree Vardhman Shwetambar Sthanakwasi Jain Shravak Sangh, Vardha',
                phone: null
            },
        ]
    },
    {
        groupName: 'East India',
        list: [
            {
                name: 'Parasdham, Kolkata',
                phone: null
            },
            {
                name: 'Calcutta Zalawadi Sthanakwasi Jain Mitra Mandal',
                phone: null
            },
            {
                name: 'Shri Liluah Sthanakwasi Jain Sangh ',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Gujarati Sangh, Raipur',
                phone: null
            },
            {
                name: 'Shree Dashashrimali Sthanakwasi Jain Sangh, Bistupur',
                phone: null
            },
            {
                name: 'Shree Raniganj Jain Shwetambar Sthanakwasi Jain Sangh',
                phone: null
            },
        ]
    },
    {
        groupName: 'United Kingdom',
        list: [
            {
                name: 'Navnat Vanik Association Of The United Kingdom',
                phone: null
            },
            {
                name: 'Jain Samaj Manchester',
                phone: null
            },
        ]
    },
    {
        groupName: 'U.S.A.',
        list: [
            {
                name: 'Jain Centre Of South Florida',
                phone: null
            },
            {
                name: 'Jain Centre Of Washington State, Seattle',
                phone: null
            },
            {
                name: 'Jain Society Of Metropolitan, Washington',
                phone: null
            },
            {
                name: 'Jain Centre Of Northern California, San Francisco',
                phone: null
            },
            {
                name: 'Parshwanath Jain Center Of Washington State',
                phone: null
            },
        ]
    },
]


export const dailySchedule = [
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "19",
        "day" : "SUN",
        "persion" : "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "20",
        "day" : "MON",
        "persion" : "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "21",
        "day" : "TUES",
        "persion" : "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "22",
        "day" : "WEN",
        "persion" : "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
]
